const networkSettings = {
  56: {
    chainId: '0x38',
    chainName: 'BSC Mainnet',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed.binance.org'],
    blockExplorerUrls: ['https://bscscan.com/'],
  },
  250: {
    chainId: '0xFA',
    chainName: 'FANTOM Mainnet',
    nativeCurrency: {
      name: 'Fantom',
      symbol: 'FTM',
      decimals: 18,
    },
    rpcUrls: ['https://rpcapi.fantom.network'],
    blockExplorerUrls: ['https://ftmscan.com/'],
  },
  0x440: {
    chainId: '0x440',
    chainName: 'METIS',
    nativeCurrency: {
      name: 'METIS',
      symbol: 'METIS',
      decimals: 18,
    },
    rpcUrls: ['https://andromeda.metis.io/?owner=0x440'],
    blockExplorerUrls: ['https://metisscan.com/'],
  },
}

const networkSetup = (chainId) => {
  return new Promise((resolve, reject) => {
    const provider = window.ethereum
    if (provider) {
      if (chainId in networkSettings) {
        provider
          .request({
            method: 'wallet_addEthereumChain',
            params: [networkSettings[chainId]],
          })
          .then(resolve)
          .catch(reject)
      } else {
        reject(new Error(`No network settings configured for chainId: '${chainId}'`))
      }
    } else {
      reject(new Error(`window.ethereum is '${typeof provider}'`))
    }
  })
}

export const getRpcUrl = () => {
  const settings = networkSettings[process.env.REACT_APP_NETWORK_ID]
  return settings.rpcUrls[0]
}

export default networkSetup
