import { MenuEntry } from 'uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://hyperjump.fi',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    href: '/swap',
  },
  {
    label: 'Asteroid Field',
    icon: 'FarmIcon',
    href: 'https://ftm-farm.hyperjump.fi',
  },
  {
    label: 'Mech Staking',
    icon: 'PoolIcon',
    href: 'https://ftm-farm.hyperjump.fi/pools',
  },
  {
    label: 'Star Vaults',
    icon: 'VaultIcon',
    href: 'https://ftm-vaults.hyperjump.fi',
  },
  {
    label: 'Analytics',
    icon: 'InfoIcon',
    href: 'https://ftm-info.hyperjump.fi',
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Github',
        href: 'https://github.com/HyperJump-DeFi',
      },
      {
        label: 'Docs',
        href: 'https://docs.hyperjump.fi',
      },
      {
        label: 'Contracts',
        href: 'https://hyperjump.fi/contracts-ftm.html',
      },
      {
        label: 'UnRekt',
        href: 'https://unrekt.hyperjump.fi',
      },
    ],
  },
]

export default config
