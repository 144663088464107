import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 16 16" {...props}>
      <path
        d="M7.58203 5.44922H11.8125V8.50781H7.58203V13.2891H4.35938V8.50781H0.117188V5.44922H4.35938V0.867188H7.58203V5.44922Z"
        fill="white"
      />
    </Svg>
  )
}

export default Icon
