import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="40px" viewBox="0 0 40 40" {...props}>
      <g id="24 / basic / circle-x">
        <path
          id="icon"
          d="M20 38.3333C9.8748 38.3333 1.66669 30.1252 1.66669 20C1.66669 9.87477 9.8748 1.66666 20 1.66666C30.1252 1.66666 38.3334 9.87477 38.3334 20C38.3334 30.1252 30.1252 38.3333 20 38.3333ZM20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 4.99999 20 4.99999C11.7158 4.99999 5.00003 11.7157 5.00003 20C5.00003 28.2843 11.7158 35 20 35ZM14.5119 27.8452L20 22.357L25.4882 27.8452L27.8452 25.4882L22.3571 20L27.8452 14.5118L25.4882 12.1548L20 17.643L14.5119 12.1548L12.1549 14.5118L17.643 20L12.1549 25.4882L14.5119 27.8452Z"
          fill="#44C4E2"
        />
      </g>
    </Svg>
  )
}

export default Icon
