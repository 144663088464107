import { createGlobalStyle } from 'styled-components'
import bg from './bg.png'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.background};
    background-image: url(${bg});
    background-size: 100% auto;
    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
