import styled from "styled-components";
import { flexbox } from "styled-system";
import Box from "./Box";
import { StyledFlexProps } from "./types";

const StyledFlex = styled(Box)<StyledFlexProps>`
  display: flex;
  ${flexbox}
`;

export default StyledFlex;
