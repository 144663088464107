import styled from 'styled-components'

export const Handle = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  left: 2px;
  position: absolute;
  transition: left 200ms ease-in;
  width: 24px;
  z-index: 1;
`

export const Input = styled.input`
  cursor: pointer;
  opacity: 0;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 3;

  &:checked + ${Handle} {
    left: calc(100% - 26px);
  }

  &:focus + ${Handle} {
    box-shadow: ${({ theme }) => theme.shadows.focus};
  }

  &:hover + ${Handle}:not(:disabled):not(:checked) {
    box-shadow: ${({ theme }) => theme.shadows.focus};
  }
`

const StyledToggle = styled.div<{ checked: boolean }>`
  align-items: center;
  border: ${({ theme }) => `2px solid ${theme.colors.primary}`};
  border-radius: 24px;
  box-shadow: ${({ theme }) => theme.shadows.inset};
  cursor: pointer;
  display: inline-flex;
  height: 33px;
  position: relative;
  transition: background-color 200ms;
  width: 71px;
`

export default StyledToggle
