import React from 'react'
import { useWeb3React } from '@web3-react/core'
import { useNetworkModal } from '../NetworkModal'
import NetworkIconButton from '../../components/Button/NetworkIconButton'
import Icon from './icons/FTMNetwork'

const btnStyle = {
  borderRadius: '50%',
  border: '0px solid',
  color: '#44C4E2',
  margin: '0',
  padding: '0',
  width: '36px',
  height: '36px',
}

const networkIconStyle = {
  borderRadius: '50%',
  border: '0px solid',
  color: '#44C4E2',
  marginRight: '0px',
  padding: '1px',
  width: '34px',
  height: '34px',
}

const NetworkBlock: React.FC = () => {
  const { onPresentNetworkModal } = useNetworkModal()
  const { chainId } = useWeb3React()

  return (
    <div style={{ marginRight: 10 }}>
      <NetworkIconButton
        type="button"
        style={btnStyle}
        startIcon="Icon"
        onClick={() => {
          onPresentNetworkModal()
        }}
      >
        {chainId === 0x440 ? <Icon style={networkIconStyle} /> : <div>Wrong Network</div>}
      </NetworkIconButton>
    </div>
  )
}

export default NetworkBlock
