import React from 'react'
import getExternalLinkProps from '../../util/getExternalLinkProps'
import NetworkModalStyledButton from './NetworkModalStyledButton'
import { ButtonProps, variants, sizes } from './types'

const NetworkModalButton: React.FC<ButtonProps> = ({
  startIcon,
  endIcon,
  children,
  external,
  isloading,
  disabled,
  ...props
}) => {
  const internalProps = external ? getExternalLinkProps() : {}
  const isDisabled = isloading || disabled

  return (
    <NetworkModalStyledButton {...internalProps} {...props} isloading={isloading} disabled={isDisabled}>
      {React.isValidElement(startIcon) &&
        React.cloneElement(startIcon, {
          mr: '0.5rem',
        })}
      {children}
      {React.isValidElement(endIcon) &&
        React.cloneElement(endIcon, {
          ml: '0.5rem',
        })}
    </NetworkModalStyledButton>
  )
}

NetworkModalButton.defaultProps = {
  variant: variants.PRIMARY,
  size: sizes.MD,
  external: false,
  isloading: false,
  disabled: false,
}

export default NetworkModalButton
