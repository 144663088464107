import { ChainId } from '@hyperjump-defi/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.METIS]: '0x804C1e0e3a7DeC7227F13B345A6623eb212AF0d7',
  [ChainId.FTM_MAINNET]: '',
  [ChainId.FTM_TESTNET]: '',
  [ChainId.BSC_MAINNET]: '',
  [ChainId.BSC_TESTNET]: '',
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
