import React from 'react'
import NetworkModalButton from '../../components/Button/NetworkModalButton'
import { Modal } from '../ModalForNetworkButton'
import BSCLogo from './icons/BinanceChainWName'
import FTMLogo from './icons/FantomChainWName'

import './icons/style.css'

interface Props {
  onDismiss?: () => void
}

const NetworkModal: React.FC<Props> = ({ onDismiss = () => null }) => {
  const bscButtonStyle = {
    padding: '24px 24px',
    borderRadius: '50px',
    marginBottom: '20px',
    fill: 'white',
  }

  const ftmButtonStyle = {
    padding: '24px 24px',
    borderRadius: '50px',
    border: '2px solid',
    borderColor: '#44C4E2',
    backgroundColor: '#1969ff',
    margin: '0px',
    opacity: '1',
  }

  const align = { alignItems: 'center', justifyContent: 'center' }

  return (
    <Modal
      title="
    Choose network"
      onDismiss={onDismiss}
    >
      <a href="https://swap.hyperjump.fi/" style={align}>
        <NetworkModalButton className="bsc" size="sm" variant="primary" style={bscButtonStyle}>
          <BSCLogo className="bsc" />
        </NetworkModalButton>
      </a>
      <div style={align}>
        <NetworkModalButton size="sm" variant="primary" style={ftmButtonStyle} onClick={onDismiss}>
          <FTMLogo className="ftm" />
        </NetworkModalButton>
      </div>
    </Modal>
  )
}

export default NetworkModal
// <img src={bsc} alt="" width="100" />
