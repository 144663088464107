import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import throttle from 'lodash/throttle'
import Overlay from '../../components/Overlay/Overlay'
import { Flex } from '../../components/Flex'
import { useMatchBreakpoints } from '../../hooks'
import Logo from './assets/Logo.png'
import HamburgerMenu from './assets/Menu.png'
import Panel from './Panel'
import UserBlock from './UserBlock'
import NetworkBlock from './NetworkBlock'

import { NavProps } from './types'
import { MENU_HEIGHT, MENU_HEIGHT_MOBILE, SIDEBAR_WIDTH_REDUCED, SIDEBAR_WIDTH_FULL } from './config'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const StyledNav = styled.nav<{ showMenu: boolean; isMobile: boolean }>`
  position: fixed;
  left: 0;
  transition: top 0.2s;
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'space-between')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  background-color: ${({ isMobile, theme }) => (isMobile ? theme.colors.nav : 'transparent')};
  padding-bottom: ${({ isMobile }) => (isMobile ? '8px' : '0')};
  align-items: center;
  padding-left: 8px;
  padding-right: 16px;
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? `${MENU_HEIGHT_MOBILE}px` : `${MENU_HEIGHT}px`)};
  z-index: 20;
  transform: translate3d(0, 0, 0);
`

const EmptyButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 16px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`

const BodyWrapper = styled.div`
  position: relative;
  display: flex;
`

const Inner = styled.div<{ isPushed: boolean; isMobile: boolean }>`
  flex-grow: 1;
  margin-top: ${({ isMobile }) => (isMobile ? `${MENU_HEIGHT_MOBILE}px` : `${MENU_HEIGHT}px`)};
  transition: margin-top 0.2s;
  transform: translate3d(0, 0, 0);
  max-width: 100%;

  ${({ theme }) => theme.mediaQueries.nav} {
    margin-left: ${({ isPushed }) => `${isPushed ? SIDEBAR_WIDTH_FULL : SIDEBAR_WIDTH_REDUCED}px`};
    max-width: ${({ isPushed }) => `calc(100% - ${isPushed ? SIDEBAR_WIDTH_FULL : SIDEBAR_WIDTH_REDUCED}px)`};
  }
`

const MobileOnlyOverlay = styled(Overlay)`
  position: fixed;
  height: 100%;

  ${({ theme }) => theme.mediaQueries.nav} {
    display: none;
  }
`

const Menu: React.FC<NavProps> = ({
  account,
  login,
  logout,
  isDark,
  toggleTheme,
  langs,
  setLang,
  currentLang,
  cakePriceUsd,
  links,
  children,
}) => {
  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false
  const [isPushed, setIsPushed] = useState(!isMobile)
  const [showMenu, setShowMenu] = useState(true)
  const refPrevOffset = useRef(window.pageYOffset)

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight
      const isTopOfPage = currentOffset === 0
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true)
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current) {
          // Has scroll up
          setShowMenu(true)
        } else {
          // Has scroll down
          setShowMenu(false)
        }
      }
      refPrevOffset.current = currentOffset
    }
    const throttledHandleScroll = throttle(handleScroll, 200)

    window.addEventListener('scroll', throttledHandleScroll)
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [])

  return (
    <Wrapper>
      <StyledNav showMenu={showMenu} isMobile={isMobile}>
        <Flex
          justifyContent={`${isMobile ? 'space-between' : 'flex-start'}`}
          style={{ width: `${isMobile ? '100%' : 'auto'}` }}
        >
          <EmptyButton type="button" onClick={() => setIsPushed((prevState: boolean) => !prevState)}>
            <img src={HamburgerMenu} alt="toggle menu" />
          </EmptyButton>
          <img src={Logo} alt="logo" />
          {isMobile && <Flex style={{ width: '48px', height: 'auto' }} />}
        </Flex>
        {isMobile && <Flex style={{ height: '10px', width: '10px' }} />}
        <Flex>
          <NetworkBlock />
          <UserBlock account={account} login={login} logout={logout} />
        </Flex>
      </StyledNav>
      <BodyWrapper>
        <Panel
          isPushed={isPushed}
          isMobile={isMobile}
          showMenu={showMenu}
          isDark={isDark}
          toggleTheme={toggleTheme}
          langs={langs}
          setLang={setLang}
          currentLang={currentLang}
          cakePriceUsd={cakePriceUsd}
          pushNav={setIsPushed}
          links={links}
        />
        <Inner isPushed={isPushed} isMobile={isMobile}>
          {children}
        </Inner>
        <MobileOnlyOverlay show={isPushed} onClick={() => setIsPushed(false)} role="presentation" />
      </BodyWrapper>
    </Wrapper>
  )
}

export default Menu
