import React from 'react'
import styled from 'styled-components'
import { Text, Spinner } from 'uikit'
import { AutoColumn } from '../Column'
import { Wrapper, Section, ConfirmedSpinner, ContentHeader } from './helpers'

const StyledLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
`

type ConfirmationPendingContentProps = { onDismiss: () => void; pendingText: string }

const ConfirmationPendingContent = ({ onDismiss, pendingText }: ConfirmationPendingContentProps) => {
  return (
    <Wrapper>
      <Section>
        <ContentHeader onDismiss={onDismiss}>Waiting for confirmation</ContentHeader>
        <ConfirmedSpinner>
          <StyledLoader>
            <Spinner />
          </StyledLoader>
        </ConfirmedSpinner>
        <AutoColumn gap="12px" justify="center">
          <AutoColumn gap="12px" justify="center">
            <Text fontSize="14px">
              <strong>{pendingText}</strong>
            </Text>
          </AutoColumn>
          <Text fontSize="14px">Confirm this transaction in your wallet</Text>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export default ConfirmationPendingContent
