import { Colors } from './types'

export const baseColors = {
  failure: '#f48d46',
  primary: '#44C4E2',
  primaryBright: '#53DEE9',
  primaryDark: '#0D1D36',
  button: '#313154',
  secondary: '#C1272D',
  success: '#31D0AA',
  warning: '#FFB237',
  white: '#FFFFFF',
  black: '#000000',
  grey: '#9797D3',
  nav: '#081933',
}

export const brandColors = {
  binance: '#F0B90B',
}

export const lightColors: Colors = {
  ...baseColors,
  ...brandColors,
  background: '#FAF9FA',
  backgroundDisabled: '#E9EAEB',
  backgroundAlt: "#FFFFFF",
  contrast: '#191326',
  invertedContrast: '#FFFFFF',
  input: '#eeeaf4',
  tertiary: '#EFF4F5',
  text: '#452A7A',
  textDisabled: '#BDC2C4',
  textSubtle: '#8f80ba',
  borderColor: '#E9EAEB',
  card: '#FFFFFF',
  gradients: {
    bubblegum: 'linear-gradient(139.73deg, #E6FDFF 0%, #F3EFFF 100%)',
  },
}

export const darkColors: Colors = {
  ...baseColors,
  ...brandColors,
  secondary: '#C1272D',
  background: '#152B4E',
  backgroundDisabled: '#3c3742',
  backgroundAlt: "#27262c",
  contrast: '#FFFFFF',
  invertedContrast: '#0d1d36',
  input: '#0d1d36',
  primaryDark: '#0D1D36',
  tertiary: '#353547',
  text: '#E0E0E0',
  textDisabled: '#666171',
  textSubtle: '#C7F2F9',
  borderColor: '#524B63',
  card: '#27262c',
  gradients: {
    bubblegum: 'linear-gradient(139.73deg, #313D5C 0%, #261e85 100%)',
  },
}
