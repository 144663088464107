import React from 'react'
import styled from 'styled-components'
import { CogIcon, SvgProps } from '../../components/Svg'
import Flex from '../../components/Flex/Flex'
import Text from '../../components/Text/Text'
import Link from '../../components/Link/Link'
import IconButton from '../../components/Button/IconButton'
import * as IconModule from './icons'
import { socials, MENU_ENTRY_HEIGHT } from './config'
import { PanelProps, PushedProps } from './types'

interface Props extends PanelProps, PushedProps {}

const Icons = (IconModule as unknown) as { [key: string]: React.FC<SvgProps> }

const Container = styled.div`
  flex: none;
  padding: 8px 4px;
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.primary}`};
  border-right: ${({ theme }) => `2px solid ${theme.colors.primary}`};
  border-bottom-right-radius: 30px;
`

const SocialEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${MENU_ENTRY_HEIGHT}px;
  padding: 0 16px;
`

const StyledSpacer = styled.div`
  width: ${(props) => props.theme.spacing[3]}px;
`

const PanelFooter: React.FC<Props> = ({ isPushed, pushNav }) => {
  if (!isPushed) {
    return (
      <Container>
        <IconButton variant="text" onClick={() => pushNav(true)}>
          <CogIcon />
        </IconButton>
      </Container>
    )
  }

  return (
    <Container>
      <Flex justifyContent="space-around" style={{ width: '100%' }}>
        <Text fontSize="small" color="primary">
          Audited by
        </Text>
      </Flex>
      <Flex justifyContent="space-around" style={{ width: '100%' }}>
        <a href="https://www.certik.org/projects/hyperjump" rel="noreferrer" target="_blank">
          <img src="/images/certik-white.png" alt="Certik Audited!" width={200} height={67} />
        </a>
        <StyledSpacer />
      </Flex>
      <SocialEntry>
        <Flex justifyContent="space-between" style={{ width: '100%' }}>
          <StyledSpacer />
          {socials.map((social, index) => {
            const Icon = Icons[social.icon]
            const iconProps = { width: '24px', color: 'textSubtle', style: { cursor: 'pointer' } }
            const mr = index < socials.length - 1 ? '24px' : 0
            return (
              <Link external key={social.label} href={social.href} aria-label={social.label} mr={mr}>
                <Icon {...iconProps} />
              </Link>
            )
          })}
          <StyledSpacer />
        </Flex>
      </SocialEntry>
    </Container>
  )
}

export default PanelFooter
