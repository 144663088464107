import React from "react";
import { StyledToggleStack, StyledToggleInput, StyledToggleLabel } from "./StyledToggleProps";
import { StyledToggleProps, scales } from "./types";

const StyledToggle: React.FC<StyledToggleProps> = ({ checked, scale = scales.MD, ...props }) => (
  <StyledToggleStack scale={scale}>
    <StyledToggleInput id={props.id || "styled-toggle"} scale={scale} type="checkbox" checked={checked} {...props} />
    <StyledToggleLabel scale={scale} checked={checked} htmlFor={props.id || "styled-toggle"}>
      <div className="styledToggles">
        <div className="styledToggle" />
        <div className="styledToggle" />
        <div className="styledToggle" />
        <div className="butter" />
      </div>
    </StyledToggleLabel>
  </StyledToggleStack>
);

StyledToggle.defaultProps = {
  scale: scales.MD,
};

export default StyledToggle;
