import { ChainId, Currency } from '@hyperjump-defi/sdk'
import React from 'react'
import { useActiveWeb3React } from 'hooks'
import useAddTokenToMetamask from 'hooks/useAddTokenToMetamask'
import styled from 'styled-components'
import { Button, LinkExternal } from 'uikit'
import { AutoColumn } from '../Column'
import { RowFixed } from '../Row'
import { getEtherscanLink } from '../../utils'
import { Wrapper, Section, ConfirmedIcon, ContentHeader } from './helpers'

type TransactionSubmittedContentProps = {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  currencyToAdd?: Currency | undefined
}

const StyledLogo = styled.img`
  margin-left: 6px;
  width: 40px;
  height: 40px;
`
const StyledButton = styled(Button)`
  border-radius: 24px;
`

const CustomConfirm = styled.img<{ size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`

const TransactionSubmittedContent = ({ onDismiss, chainId, hash, currencyToAdd }: TransactionSubmittedContentProps) => {
  const { library } = useActiveWeb3React()
  const { addToken, success } = useAddTokenToMetamask(currencyToAdd)

  return (
    <Wrapper>
      <Section>
        <ContentHeader onDismiss={onDismiss}>Transaction submitted</ContentHeader>
        <ConfirmedIcon>
          <CustomConfirm src="/images/tx_confirmation.svg" alt="confirmed" size="192px" />
        </ConfirmedIcon>
        <AutoColumn gap="8px" justify="center">
          {chainId && hash && (
            <LinkExternal href={getEtherscanLink(chainId, hash, 'transaction')}>View TX on METISScan.com</LinkExternal>
          )}
          <div>
            {currencyToAdd && library?.provider?.isMetaMask && (
              <StyledButton mt="8px" padding="24px 36x" fullwidth onClick={!success ? addToken : onDismiss}>
                {!success ? (
                  <RowFixed>
                    {currencyToAdd.symbol} +{' '}
                    <StyledLogo src="https://raw.githubusercontent.com/MetaMask/brand-resources/master/SVG/metamask-fox.svg" />
                  </RowFixed>
                ) : (
                  <RowFixed>Added {currencyToAdd.symbol}</RowFixed>
                )}
              </StyledButton>
            )}
          </div>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export default TransactionSubmittedContent
