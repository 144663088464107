import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'
import './style.css'

const Logo: React.FC<SvgProps> = () => {
  return (
    <Svg className="bsc" width="83" height="20" viewBox="0 0 83 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M2.25815 7.7419L3.05176e-05 9.99997L2.2581 12.2581L4.51623 10L2.25815 7.7419Z" />
        <path d="M10 4.51609L13.8711 8.38703L16.1291 6.12906L10 0L3.87109 6.12906L6.12906 8.38703L10 4.51609Z" />
        <path d="M17.742 7.74195L15.4839 9.99997L17.742 12.2581L20.0001 10L17.742 7.74195Z" />
        <path d="M10 15.4839L6.12906 11.613L3.87109 13.8709L10 20L16.1291 13.8709L13.8711 11.613L10 15.4839Z" />
        <path d="M10.0001 7.74194L7.74197 9.99997L10 12.2581L12.2581 10L10.0001 7.74194Z" />
        <path d="M28.2689 5.41108C28.8955 5.05952 29.4236 4.50795 29.4236 3.51733V3.49233C29.4236 2.11264 28.3189 1.22202 26.5267 1.22202H22.4517V10.0001H26.6267C28.608 10.0001 29.9111 9.19702 29.9111 7.59233V7.56733C29.9111 6.3892 29.2845 5.80014 28.2689 5.41108ZM24.3314 2.91577H26.2377C27.0533 2.91577 27.5048 3.24077 27.5048 3.81889V3.84389C27.5048 4.49545 26.9658 4.77045 26.1127 4.77045H24.3314V2.91577ZM27.9939 7.35483C27.9939 8.00639 27.4798 8.30639 26.6267 8.30639H24.3314V6.3767H26.5642C27.5548 6.3767 27.9939 6.7392 27.9939 7.32827V7.35483Z" />
        <path d="M33.9613 1.2225H32.0302V10H33.9613V1.2225Z" />
        <path d="M44.3416 10V1.2225H42.4356V6.62704L38.3227 1.2225H36.5422V10H38.4481V4.42L42.6991 10H44.3416Z" />
        <path d="M51.2767 1.15955H49.4955L45.7345 10.0002H47.7033L48.5048 8.03142H52.2173L53.0189 10.0002H55.0377L51.2767 1.15955ZM49.1955 6.32673L50.3611 3.47986L51.5267 6.32673H49.1955Z" />
        <path d="M64.2428 10V1.2225H62.3367V6.62704L58.2239 1.2225H56.4433V10H58.3492V4.42L62.6002 10H64.2428Z" />
        <path d="M74.212 8.58315L72.9833 7.34174C72.2936 7.96877 71.6792 8.37002 70.6761 8.37002C69.1713 8.37002 68.1305 7.11611 68.1305 5.61127V5.58627C68.1305 4.08143 69.1964 2.85268 70.6761 2.85268C71.5538 2.85268 72.2434 3.22877 72.9206 3.8433L74.1494 2.42627C73.3344 1.62377 72.3436 1.07205 70.6886 1.07205C67.9925 1.07205 66.1117 3.11596 66.1117 5.61127V5.63627C66.1117 8.15674 68.0303 10.1505 70.6133 10.1505C72.3061 10.1505 73.3092 9.54877 74.212 8.58315Z" />
        <path d="M82.4509 10V8.28219H77.6861V6.43891H81.8241V4.72094H77.6861V2.94047H82.3883V1.2225H75.7677V10H82.4509Z" />
        <path d="M23.3235 14.2391C23.3235 13.8466 23.6742 13.5376 24.2754 13.5376C24.8097 13.5376 25.3358 13.7464 25.8618 14.1389L26.4129 13.3623C25.8161 12.8725 25.064 12.6119 24.2921 12.6275C23.1314 12.6275 22.2964 13.3122 22.2964 14.3309C22.2964 15.4164 22.9978 15.7837 24.242 16.0844C25.3274 16.3348 25.5612 16.5603 25.5612 16.9861C25.5612 17.437 25.1604 17.7292 24.5259 17.7292C23.7994 17.7292 23.24 17.4537 22.6805 16.9694L22.0627 17.7042C22.7297 18.3101 23.5997 18.6438 24.5008 18.6394C25.7282 18.6394 26.5883 17.9881 26.5883 16.8859C26.5883 15.9091 25.9453 15.4664 24.7095 15.1659C23.5907 14.8987 23.3235 14.6983 23.3235 14.2391Z" />
        <path d="M32.5137 14.3644V18.5559H33.5407V12.7111H32.4469L30.6684 15.4748L28.8899 12.7111H27.7961V18.5559H28.8064V14.3809L30.635 17.1197H30.6684L32.5137 14.3644Z" />
        <path d="M38.0677 12.6692H37.1158L34.5441 18.5559H35.5962L36.1974 17.1448H38.9611L39.5541 18.5559H40.6395L38.0677 12.6692ZM38.5855 16.2347H36.5731L37.5751 13.8967L38.5855 16.2347Z" />
        <path d="M46.6016 18.5559L45.0235 16.3433C45.8418 16.1178 46.4179 15.5333 46.4179 14.5647C46.4179 13.4208 45.5913 12.7111 44.2469 12.7111H41.6417V18.5559H42.6688V16.5186H43.9547L45.3908 18.5559H46.6016ZM45.3742 14.6231C45.3742 15.2244 44.9066 15.6084 44.1717 15.6084H42.6688V13.6462H44.1635C44.9233 13.6462 45.3742 13.9802 45.3742 14.6231Z" />
        <path d="M50.123 13.663H51.9766V12.7111H47.2339V13.663H49.0875V18.5559H50.123V13.663Z" />
        <path d="M60.6541 17.6208L59.9944 16.9528C59.4934 17.4203 59.0342 17.7127 58.3161 17.7127C57.1972 17.7127 56.3873 16.7774 56.3873 15.6252C56.3873 14.4728 57.1972 13.5544 58.3161 13.5544C58.9841 13.5544 59.4852 13.8466 59.9527 14.2808L60.6123 13.521C60.3153 13.2184 59.9584 12.9812 59.5645 12.8245C59.1705 12.6677 58.7482 12.595 58.3245 12.6108C56.5544 12.6108 55.3102 13.9719 55.3102 15.6419C55.3102 17.3285 56.5794 18.6561 58.2827 18.6561C59.3766 18.6561 60.0445 18.2553 60.6541 17.6208Z" />
        <path d="M65.5494 16.0927V18.5559H66.5764V12.7111H65.5494V15.1408H62.7606V12.7111H61.7336V18.5559H62.7606V16.0927H65.5494Z" />
        <path d="M71.1002 12.6692H70.1483L67.5764 18.5559H68.6286L69.2297 17.1448H71.9936L72.5864 18.5559H73.6719L71.1002 12.6692ZM71.6178 16.2347H69.6055L70.6075 13.8967L71.6178 16.2347Z" />
        <path d="M74.7327 12.7111V18.5559H75.7597V12.7111H74.7327Z" />
        <path d="M81.4073 16.7523L78.2761 12.7111H77.3242V18.5559H78.3345V14.3977L81.5577 18.5559H82.4177V12.7111H81.4073V16.7523Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="82.4509" height="20" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default Logo
