import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { useAudioModeManager } from 'state/user/hooks'
import { IconButton, Text, Flex, useModal } from 'uikit'
import SettingsModal from './SettingsModal'
import RecentTransactionsModal from './RecentTransactionsModal'

interface PageHeaderProps {
  title: ReactNode
  description?: ReactNode
  children?: ReactNode
}

// TODO: use UI Kit
const CogIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.43 12.98C19.47 12.66 19.5 12.34 19.5 12C19.5 11.66 19.47 11.34 19.43 11.02L21.54 9.37C21.73 9.22 21.78 8.95 21.66 8.73L19.66 5.27C19.54 5.05 19.27 4.97 19.05 5.05L16.56 6.05C16.04 5.65 15.48 5.32 14.87 5.07L14.49 2.42C14.46 2.18 14.25 2 14 2H9.99996C9.74996 2 9.53996 2.18 9.50996 2.42L9.12996 5.07C8.51996 5.32 7.95996 5.66 7.43996 6.05L4.94996 5.05C4.71996 4.96 4.45996 5.05 4.33996 5.27L2.33996 8.73C2.20996 8.95 2.26996 9.22 2.45996 9.37L4.56996 11.02C4.52996 11.34 4.49996 11.67 4.49996 12C4.49996 12.33 4.52996 12.66 4.56996 12.98L2.45996 14.63C2.26996 14.78 2.21996 15.05 2.33996 15.27L4.33996 18.73C4.45996 18.95 4.72996 19.03 4.94996 18.95L7.43996 17.95C7.95996 18.35 8.51996 18.68 9.12996 18.93L9.50996 21.58C9.53996 21.82 9.74996 22 9.99996 22H14C14.25 22 14.46 21.82 14.49 21.58L14.87 18.93C15.48 18.68 16.04 18.34 16.56 17.95L19.05 18.95C19.28 19.04 19.54 18.95 19.66 18.73L21.66 15.27C21.78 15.05 21.73 14.78 21.54 14.63L19.43 12.98ZM12 15.5C10.07 15.5 8.49996 13.93 8.49996 12C8.49996 10.07 10.07 8.5 12 8.5C13.93 8.5 15.5 10.07 15.5 12C15.5 13.93 13.93 15.5 12 15.5Z"
      fill="currentColor"
    />
  </svg>
)

const HistoryIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 3C8.03 3 4 7.03 4 12H1L4.89 15.89L4.96 16.03L9 12H6C6 8.13 9.13 5 13 5C16.87 5 20 8.13 20 12C20 15.87 16.87 19 13 19C11.07 19 9.32 18.21 8.06 16.94L6.64 18.36C8.27 19.99 10.51 21 13 21C17.97 21 22 16.97 22 12C22 7.03 17.97 3 13 3ZM12 8V13L16.28 15.54L17 14.33L13.5 12.25V8H12Z"
      fill="currentColor"
    />
  </svg>
)

const VoiceIcon = () => (
  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.8641 2.13207C13.7485 2.07558 13.6194 2.05267 13.4915 2.06596C13.3635 2.07926 13.2419 2.12822 13.1404 2.20727L7.13817 6.87475H3.2495C2.88493 6.87511 2.53541 7.02009 2.27762 7.27788C2.01984 7.53566 1.87486 7.88519 1.8745 8.24975V13.7498C1.87486 14.1143 2.01984 14.4638 2.27762 14.7216C2.53541 14.9794 2.88493 15.1244 3.2495 15.1248H7.13817L13.1404 19.7922C13.2419 19.8713 13.3636 19.9202 13.4916 19.9335C13.6196 19.9468 13.7487 19.9239 13.8643 19.8673C13.9799 19.8108 14.0773 19.7229 14.1454 19.6137C14.2135 19.5045 14.2496 19.3784 14.2495 19.2498V2.74975C14.2496 2.62103 14.2135 2.49487 14.1454 2.38566C14.0772 2.27645 13.9798 2.18859 13.8641 2.13207Z" fill="#44C4E2"/>
    <path d="M16.9995 8.25C16.8172 8.25001 16.6423 8.32244 16.5134 8.45137C16.3844 8.5803 16.312 8.75517 16.312 8.9375V13.0625C16.312 13.2448 16.3844 13.4197 16.5134 13.5486C16.6423 13.6776 16.8172 13.75 16.9995 13.75C17.1818 13.75 17.3567 13.6776 17.4856 13.5486C17.6146 13.4197 17.687 13.2448 17.687 13.0625V8.9375C17.687 8.75517 17.6146 8.5803 17.4856 8.45137C17.3567 8.32244 17.1818 8.25001 16.9995 8.25Z" fill="#44C4E2"/>
    <path d="M19.7495 6.875C19.5672 6.87501 19.3923 6.94744 19.2634 7.07637C19.1344 7.2053 19.062 7.38017 19.062 7.5625V14.4375C19.062 14.6198 19.1344 14.7947 19.2634 14.9236C19.3923 15.0526 19.5672 15.125 19.7495 15.125C19.9318 15.125 20.1067 15.0526 20.2356 14.9236C20.3646 14.7947 20.437 14.6198 20.437 14.4375V7.5625C20.437 7.38017 20.3646 7.2053 20.2356 7.07637C20.1067 6.94744 19.9318 6.87501 19.7495 6.875Z" fill="#44C4E2"/>
  </svg>
)

const VoiceMuteIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.498 13.75C16.6803 13.75 16.8552 13.6776 16.9841 13.5486C17.113 13.4197 17.1855 13.2448 17.1855 13.0625V8.9375C17.1855 8.75516 17.1131 8.5803 16.9841 8.45136C16.8552 8.32243 16.6803 8.25 16.498 8.25C16.3156 8.25 16.1408 8.32243 16.0118 8.45136C15.8829 8.5803 15.8105 8.75516 15.8105 8.9375V13.0625C15.8105 13.2448 15.8829 13.4197 16.0119 13.5486C16.1408 13.6776 16.3157 13.75 16.498 13.75Z" fill="#44C4E2"/>
    <path d="M19.248 6.875C19.0657 6.87501 18.8908 6.94744 18.7619 7.07637C18.6329 7.2053 18.5605 7.38017 18.5605 7.5625V14.4375C18.5605 14.6198 18.6329 14.7947 18.7618 14.9236C18.8908 15.0526 19.0656 15.125 19.248 15.125C19.4303 15.125 19.6052 15.0526 19.7341 14.9236C19.8631 14.7947 19.9355 14.6198 19.9355 14.4375V7.5625C19.9355 7.38017 19.863 7.2053 19.7341 7.07637C19.6052 6.94744 19.4303 6.87501 19.248 6.875Z" fill="#44C4E2"/>
    <path d="M4.63374 2.975C4.51108 2.84007 4.33984 2.7594 4.1577 2.75072C3.97555 2.74205 3.79743 2.80609 3.6625 2.92875C3.52757 3.05142 3.4469 3.22266 3.43823 3.4048C3.42955 3.58694 3.49359 3.76507 3.61626 3.9L6.32081 6.875H2.75C2.38546 6.87542 2.03596 7.02042 1.77819 7.27819C1.52042 7.53596 1.37542 7.88546 1.375 8.25V13.75C1.37542 14.1145 1.52042 14.464 1.77819 14.7218C2.03596 14.9796 2.38546 15.1246 2.75 15.125H6.63909L12.6405 19.7927C12.742 19.8717 12.8638 19.9206 12.9918 19.9338C13.1198 19.9471 13.249 19.9241 13.3646 19.8676C13.4802 19.811 13.5776 19.7232 13.6457 19.614C13.7139 19.5048 13.75 19.3787 13.75 19.25V15.0471L17.3663 19.025C17.427 19.0918 17.5003 19.146 17.582 19.1845C17.6637 19.223 17.7521 19.245 17.8423 19.2493C17.9325 19.2536 18.0226 19.2401 18.1076 19.2095C18.1926 19.179 18.2707 19.132 18.3375 19.0712C18.4043 19.0105 18.4585 18.9372 18.497 18.8555C18.5355 18.7738 18.5575 18.6854 18.5618 18.5952C18.5661 18.505 18.5526 18.4149 18.522 18.3299C18.4915 18.2449 18.4445 18.1668 18.3837 18.1L4.63374 2.975Z" fill="#44C4E2"/>
    <path d="M12.5538 9.64312C12.6471 9.7458 12.7694 9.81782 12.9044 9.84973C13.0395 9.88163 13.181 9.87192 13.3104 9.82188C13.4399 9.77184 13.5511 9.68381 13.6296 9.56936C13.708 9.4549 13.75 9.31939 13.75 9.18062V2.75C13.75 2.6213 13.7139 2.49519 13.6458 2.38601C13.5776 2.27682 13.4802 2.18896 13.3646 2.13242C13.249 2.07588 13.1198 2.05292 12.9918 2.06617C12.8638 2.07942 12.7421 2.12833 12.6405 2.20735L9.21591 4.87091C9.14113 4.92906 9.07917 5.00203 9.03393 5.08526C8.98868 5.16849 8.96112 5.26016 8.95298 5.35454C8.94484 5.44892 8.9563 5.54397 8.98662 5.63371C9.01695 5.72345 9.0655 5.80596 9.12922 5.87606L12.5538 9.64312Z" fill="#44C4E2"/>
  </svg>
)

const StyledPageHeader = styled.div`
  padding: 28px 24px 0;
`

const Details = styled.div`
  flex: 1;
`

const PageHeader = ({ title, description, children }: PageHeaderProps) => {
  const [onPresentSettings] = useModal(<SettingsModal />)
  const [onPresentRecentTransactions] = useModal(<RecentTransactionsModal />)
  const [audioPlay, toggleSetAudioMode] = useAudioModeManager()

  return (
    <StyledPageHeader>
      <Flex alignItems="center">
        <Details>
          <Text mb="8px" bold fontSize="24px" color="primary" style={{ fontFamily: 'Oswald' }}>
            {title}
          </Text>
          {description && (
            <Text color="white" fontSize="14px" bold>
              {description}
            </Text>
          )}
        </Details>
        <IconButton variant="text" onClick={toggleSetAudioMode} title="Voice">
          { audioPlay && <VoiceIcon /> }
          { !audioPlay && <VoiceMuteIcon /> }
        </IconButton>
        <IconButton variant="text" onClick={onPresentSettings} title="Settings">
          <CogIcon />
        </IconButton>
        <IconButton variant="text" onClick={onPresentRecentTransactions} title="Recent transactions">
          <HistoryIcon />
        </IconButton>
      </Flex>
      {children && <Text mt="16px">{children}</Text>}
    </StyledPageHeader>
  )
}

export default PageHeader
